import useSWR from 'swr'
import ApiError from 'lib/network/ApiError'
import CondensedProduct from 'types/CondensedProduct'
import useCart from './useCart'

const useFrequentlyBoughtTogetherAlgolia = () => {
  const { data: cart } = useCart()
  let productIds = (cart && cart.lineItems.map(li => li.productId)) || []
  productIds = productIds.slice(productIds.length - 3).sort() // Only use the last 3 ids for recommendation

  const { data, ...result } = useSWR<
    { frequentlyBoughtTogetherAlgolia: CondensedProduct[] },
    ApiError
  >(
    () =>
      !cart?.id ? null : `/api/v2/products/frequently_bought_together?product_ids=${productIds}`,
    {
      revalidateOnFocus: false,
      isPaused: () => false,
    },
  )

  return {
    ...result,
    // Sort frequentlyBoughtTogetherAlgolia by confidenceScore
    data: data?.frequentlyBoughtTogetherAlgolia?.sort(
      (a, b) => b.confidenceScore - a.confidenceScore,
    ),
  }
}

export default useFrequentlyBoughtTogetherAlgolia
