import useSWR from 'swr'
import { post } from 'lib/network/fetchers/everlane'
import ApiError from 'lib/network/ApiError'
import useSession from 'lib/network/hooks/useSession'
import { setCartValue } from 'lib/helpers/wunderkind'
import OrderPreview from 'types/OrderPreview'

type OrderPreviewAddress = {
  id?: number
  city?: string
  country?: string
  postalCode?: string
  regionCode?: string
}

type OrderPreviewParams = {
  address?: OrderPreviewAddress
  applePay?: boolean
  cartId?: number
  fulfillmentMethodKey?: string
  hasCarbonOffset?: boolean
  newCustomerOptIn?: boolean
  previewPromoCode?: string
  includePromosInSubtotal?: boolean
  cart?: boolean
}

const ORDER_PREVIEW_PATH = '/api/v2/orders/preview'

export const getOrderPreview = async (params: OrderPreviewParams = {}) => {
  try {
    return await post<OrderPreview>(ORDER_PREVIEW_PATH, params)
  } catch (error) {
    if (error instanceof ApiError && error.code === '400' && error.responseJSON) {
      return error.responseJSON
    }

    throw error
  }
}

const useOrderPreview = ({
  address,
  applePay,
  cartId,
  fulfillmentMethodKey,
  hasCarbonOffset,
  newCustomerOptIn,
  previewPromoCode,
  includePromosInSubtotal = true,
  cart = false,
}: OrderPreviewParams = {}) => {
  const { data: session } = useSession()
  const addressCacheKey = address?.id || address?.country || null

  const cacheKey = [
    addressCacheKey,
    applePay,
    cartId,
    fulfillmentMethodKey,
    hasCarbonOffset,
    newCustomerOptIn,
    previewPromoCode,
    cart,
  ]

  const params = {
    includePromosInSubtotal,
    address,
    applePay,
    cartId,
    fulfillmentMethodKey,
    hasCarbonOffset,
    newCustomerOptIn,
    previewPromoCode,
    cart,
  }

  const { data, ...result } = useSWR<OrderPreview, ApiError>(
    () => (session ? [ORDER_PREVIEW_PATH, ...cacheKey] : null),
    () => getOrderPreview(params),
    { revalidateIfStale: true },
  )

  const { totalDecimal, lineItemPrices } = data || {}
  // wunderkind
  setCartValue(lineItemPrices?.length > 0 ? totalDecimal : 0)

  return { ...result, data }
}

export default useOrderPreview
