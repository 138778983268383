import ProductPrices from 'types/ProductPrices'

const PDP_GST_TEXT_AU = 'Price includes GST'
const PDP_DUTY_AND_TAX_TEXT_DEFAULT = 'Price includes duties and VAT'
const NO_DUTY_TEXT_CA = 'No duties. Taxes calculated at checkout.'

export const formatPrice = (
  currencySymbol: string,
  value: string | number | null,
  digits = 2,
): string => {
  let valueFloat: number
  if (typeof value === 'string') {
    valueFloat = parseFloat(value)
  } else {
    valueFloat = value
  }
  if (!valueFloat || Number.isNaN(valueFloat)) return ''
  return `${currencySymbol}${valueFloat.toFixed(digits)}`
}

export const determinePrice = ({ basePrice }) => {
  return parseFloat(basePrice)
}

export const truncatePriceString = (price: string) => {
  const decimalIndex = price.indexOf('.')
  if (decimalIndex === -1) return price
  return price.slice(0, decimalIndex)
}

export const shouldShowEmbeddedDuties = ({
  shippingCountry,
  embeddedFLCOverride,
  embeddedDutyCountries,
}) => {
  const isEmbeddedDutyCountry = Object.entries(embeddedDutyCountries || {})
    .map(([_, country]) => country)
    ?.includes(shippingCountry)
  const embeddedFLCEnabled = embeddedFLCOverride !== 'none'
  return isEmbeddedDutyCountry && embeddedFLCEnabled
}

export const shouldShowEmbeddedTax = ({ shippingCountry, embeddedTaxCountries }) => {
  return Object.entries(embeddedTaxCountries || {})
    .map(([_, country]) => country)
    ?.includes(shippingCountry)
}

export const isDutyFree = ({ shippingCountry, dutyFreeCountries }) => {
  return dutyFreeCountries?.indexOf(shippingCountry) >= 0
}

export const getPDPDutyAndTaxText = ({
  shippingCountry,
  embeddedFLCOverride,
  embeddedDutyCountries,
  embeddedTaxCountries,
}) => {
  const hasEmbeddedDuties = shouldShowEmbeddedDuties({
    shippingCountry,
    embeddedFLCOverride,
    embeddedDutyCountries,
  })
  const hasEmbeddedTax = shouldShowEmbeddedTax({ shippingCountry, embeddedTaxCountries })
  if (hasEmbeddedDuties) {
    if (hasEmbeddedTax) {
      if (shippingCountry === 'AU') {
        return PDP_GST_TEXT_AU
      }
      return PDP_DUTY_AND_TAX_TEXT_DEFAULT
    }
  }

  if (shippingCountry === 'CA') {
    return NO_DUTY_TEXT_CA
  }

  return null
}

export const getProductPrice = (productPrices: ProductPrices, productId: number) => {
  return productPrices?.products.find(product => product.id === productId)
}
