import { useState, useContext } from 'react'
import classNames from 'classnames'
import { Form, Input } from '@everlane/form'
import Button from '@everlane/button'

import useInput from 'lib/hooks/useInput'
import useSession from 'lib/network/hooks/useSession'
import useAddToCart from 'lib/network/hooks/useAddToCart'
import isValidEmail from 'lib/helpers/verify_email'
import importEverlaneIcon, { Icon } from 'lib/iconProps'
import { determinePrice } from 'lib/helpers/pricing'
import { publishEvent } from 'lib/events/tracking'

import NavigationContext from 'contexts/NavigationContext'
import Card from 'components/core/Card'

import Event from 'types/Event'

import styles from './styles.module.scss'

const ALLOWED_MESSAGE_LENGTH = 240

const Chevron = importEverlaneIcon(Icon.CHEVRON)
const Calendar = importEverlaneIcon(Icon.CALENDAR)

type GiftcardModalProps = {
  onCloseCard: () => void
  isCardOpen: boolean
  presetValues?: any
}

type GiftcardError = {
  [field: string]: { text: string }
}

function timeZoneAbbr(date = new Date()): String {
  // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
  try {
    const format = new Intl.DateTimeFormat([], { timeZoneName: 'short' })
    const parts = format.formatToParts(date)
    return parts?.find(p => p.type === 'timeZoneName')?.value ?? ''
  } catch (e) {
    return ''
  }
}

const GiftcardModal = ({ onCloseCard, isCardOpen }: GiftcardModalProps) => {
  const { data: session } = useSession()
  const { giftcardModalEditingData, selectedGiftCardDesign, product, openAddToCartModal } =
    useContext(NavigationContext)
  const { presetValues, price, updateGiftcardLineItem } = giftcardModalEditingData || {}

  const { value: name, setValue: setName } = useInput(presetValues?.recipientName || '')
  const { value: email, setValue: setEmail } = useInput(presetValues?.recipientEmail || '')
  const { value: message, bind: bindMessage } = useInput(presetValues?.message || '')
  const [errors, setErrors] = useState<GiftcardError>({})

  const size = product?.productSizes?.find(s => s.name === 'Digital')
  const cartId = session?.cart.id

  let localDatetime
  if (presetValues?.notifyAt) {
    const dateTime = new Date(presetValues?.notifyAt)
    const timezoneOffset = dateTime.getTimezoneOffset() * 60000
    localDatetime = new Date(dateTime.getTime() - timezoneOffset).toISOString().split('.') // removes timezone
  }
  const { value: date, bind: bindDate } = useInput(presetValues?.notifyAt ? localDatetime[0] : '')

  const validateForm = () => {
    const errorObject = {} as GiftcardError

    if (!name) {
      errorObject.name = { text: '(Required)' }
    }

    if (!email) {
      errorObject.email = { text: '(Required)' }
    }

    if (!date) {
      errorObject.date = { text: '(Required)' }
    }

    if (email && !isValidEmail(email)) {
      errorObject.email = { text: '(Not a valid email)' }
    }

    const emojiRegex =
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g
    if (name && emojiRegex.test(name)) {
      errorObject.name = { text: '(We cannot send emojis)' }
    }

    setErrors(errorObject)
    return { hasErrors: Object.keys(errorObject).length > 0 }
  }

  const { mutate: addToCart } = useAddToCart({
    initialParams: {
      lineItem: {
        productId: product?.id,
        productSizeId: size?.id,
        collectionId: product?.primaryCollection?.id,
        quantity: 1,
        price: determinePrice({ basePrice: product?.price }),
      },
      withCart: cartId,
    },
    onCompleted: data => {
      const { id, displayName, productSku: sku, productGroupId, mainImage } = product

      const timestamp = Math.trunc(Date.now() / 1000).toString()
      const urlParams = new URLSearchParams(global?.window?.location.search)
      const navigatedCollection = urlParams.get('collection') || null

      publishEvent(Event.Checkout.ADD_TO_CART, {
        variant_id: data.lineItem.variantId,
        collection: navigatedCollection,
        location: 'add_to_bag',
        bundle_item: false,
        product: {
          id,
          displayName,
          usdPrice: `${price}`,
          primaryCollection: product.primaryCollection,
          sku,
          productGroupId,
          mainImage,
        },
        timestamp,
      })

      openAddToCartModal()
    },
  })

  const addGiftcardToCart = giftcardParams => {
    const giftcardLineItemParams = {
      lineItem: {
        productId: product?.id,
        productSizeId: size?.id,
        collectionId: product?.primaryCollection?.id,
        quantity: 1,
        price: determinePrice({ basePrice: product?.price }),
        unitAttributes: giftcardParams.unit,
        unitType: giftcardParams.unitType,
      },
      withCart: cartId,
    }

    addToCart(giftcardLineItemParams)
  }

  const handleSubmit = () => {
    const { hasErrors } = validateForm()
    if (hasErrors) return

    const notifyAt = date ? new Date(date).toUTCString() : null

    const unit = {
      amount: price,
      recipientName: name,
      recipientEmail: email,
      notifyAt,
      message,
      giftcardDesignId: selectedGiftCardDesign?.key,
    }

    if (!updateGiftcardLineItem) {
      addGiftcardToCart({ unit, unitType: 'GiftcardReservation' })
    }

    if (updateGiftcardLineItem) {
      updateGiftcardLineItem(unit)
    }

    onCloseCard()
  }

  const title = presetValues?.recipientEmail
    ? `Digital Gift Card — $${price}`
    : "Who's the lucky recipient?"

  const futureYear = new Date().getFullYear() + 1
  const futureDate = new Date()
  futureDate.setFullYear(futureYear)

  return (
    <Card
      isCardOpen={isCardOpen || true}
      className={styles['giftcard-modal']}
      onCloseCard={onCloseCard}
    >
      <div className={styles['giftcard-modal__title']}>{title}</div>
      <Form className={styles['giftcard-modal__form']} onSubmit={handleSubmit}>
        <Input
          name="name"
          defaultValue={name}
          onChange={setName}
          label="Recipient Name"
          showValidationIcon={false}
          isRequired
        />
        <Input
          name="email"
          type="email"
          label="Recipient Email"
          validate={value => isValidEmail(value)}
          showValidationIcon={false}
          validateErrorMessage={errors.email && errors.email.text}
          defaultValue={email}
          onChange={setEmail}
          isRequired
        />
        <label className={styles['giftcard-modal__field']} htmlFor="date">
          {errors.date && (
            <span className={styles['giftcard-modal__error']}>{errors.date.text}</span>
          )}
          <Chevron className={styles['giftcard-modal__input--date-caret']} />
          <Calendar
            className={classNames(styles['giftcard-modal__input--date-calendar'], {
              [styles['has-value']]: date?.length > 0,
            })}
          />
          <span
            className={classNames(styles['giftcard-modal__date-input-label'], {
              [styles['has-value']]: date?.length > 0,
            })}
          >
            {`Delivery Date & Time (${timeZoneAbbr()})`}
          </span>
          <input
            className={classNames(
              'no-date-placeholder',
              styles['giftcard-modal__input'],
              styles['giftcard-modal__input--date'],
              {
                [styles['has-value']]: date?.length > 0,
              },
            )}
            id="date"
            type="datetime-local"
            {...bindDate}
            min={new Date().toISOString().slice(0, 16)}
            max={futureDate.toISOString().slice(0, 16)}
            required
          />
        </label>
        <label className={styles['giftcard-modal__field']} htmlFor="message">
          <textarea
            className={styles['giftcard-modal__input']}
            placeholder="Message (Optional)"
            id="message"
            {...bindMessage}
          />
          <span className={styles['giftcard-modal__label']}>{`You have ${
            ALLOWED_MESSAGE_LENGTH - message?.length
          } out of ${ALLOWED_MESSAGE_LENGTH} characters left`}</span>
        </label>
        <Button
          type="submit"
          variant="primary"
          width="full"
          disabled={!name || !email || !date}
          className={styles['giftcard-modal__button']}
        >
          {presetValues ? 'Save' : 'Add Card'}
        </Button>
      </Form>
    </Card>
  )
}

export default GiftcardModal
