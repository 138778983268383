import { createContext } from 'react'

export type OverlaySource =
  | 'flyout_cart'
  | 'complete_the_look'
  | 'more_to_love'
  | 'desired_size'
  | 'builder_product_tile'
  | 'builder_ingrid'
  | 'image_hotspot'

interface OverlayProductContext {
  overlayPermalink: string
  setOverlayPermalink: (permalink: string) => void
  selectedOverlaySize: string
  setSelectedOverlaySize: (size: string) => void
  overlayPreviewedSize: string
  setOverlayPreviewedSize: (size: string) => void
  overlayError: string
  setOverlayError: (arg0: string) => void
  showOverlay: boolean
  setShowOverlay: (boolean) => void
  overlaySource: OverlaySource
  setOverlaySource: (source: OverlaySource) => void
  multiProductPermalinks: string[]
  setMultiProductPermalinks: (permalinks: string[]) => void
  isMultiProductOverlay: boolean
  setIsMultiProductOverlay: (boolean) => void
}

const Context = createContext<Partial<OverlayProductContext>>({})

export default Context
