import dynamic from 'next/dynamic'

interface IconProps {
  width?: number | string
  height?: number | string
  strokeWidth?: number | string
  className?: string
  countryCode?: string
  onClick?: () => void
}

export enum Icon {
  ARROW = 'Arrow',
  CALENDAR = 'Calendar',
  CART = 'Cart',
  CHECK = 'Check',
  CHEVRON = 'Chevron',
  CIRCLE_CHECK = 'CircleCheck',
  CIRCLE_X = 'CircleX',
  COPY = 'Copy',
  FILTER = 'Filter',
  FLAG = 'Flag',
  INFO = 'Info',
  IMAGE = 'Image',
  LEAF = 'Leaf',
  LINK = 'Link',
  LOADING_SPINNER = 'LoadingSpinner',
  LOCATION_DROP = 'LocationDrop',
  LOCK = 'Lock',
  MAIL = 'Mail',
  MENU = 'Menu',
  MESSAGE_SQUARE = 'MessageSquare',
  MINUS = 'Minus',
  PLAY = 'Play',
  PLUS = 'Plus',
  SEARCH = 'Search',
  TRASH = 'Trash',
  USER = 'User',
  X = 'X',
}

type IconComponent = React.ComponentType<IconProps>

const importEverlaneIcon = (icon: Icon): IconComponent =>
  dynamic<IconProps>(() => import('@everlane/icon').then(mod => mod[icon]), { ssr: false })

export default importEverlaneIcon
