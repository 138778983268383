import { createContext } from 'react'

export type CPTileTreatmentType = string

interface ExperimentContextType {
  CPTileTreatment: CPTileTreatmentType
  setCPTileTreatment: (treatment: CPTileTreatmentType) => void
}

const ExperimentContext = createContext<Partial<ExperimentContextType>>({})

export default ExperimentContext
