import useOrderPreview from 'lib/network/hooks/useOrderPreview'
import useFrequentlyBoughtTogetherAlgolia from 'lib/network/hooks/useFrequentlyBoughtTogetherAlgolia'
import Cart from 'types/Cart'
import LineItem from 'types/LineItem'
import GiftcardReservation from 'types/GiftcardReservation'
import useMutation from '../useMutation'
import { UseMutationOptions } from '../types'

type AddToCartParams = {
  lineItem: {
    quantity: number
    productId: number
    productSizeId: number
    collectionId: number
    price: number
  }
  withCart?: number
  unitType?: string
  unitAttributes: GiftcardReservation
}

type AddToCartResponse = {
  cart: Cart
  lineItem: LineItem
}

interface UseAddToCartParams extends UseMutationOptions<AddToCartResponse, AddToCartParams> {}

const useAddToCart = (args: UseAddToCartParams = {}) => {
  const mutation = useMutation<AddToCartResponse, AddToCartParams>('/api/v2/line_items', null, {
    ...args,
    keysToRevalidate: [
      '/api/v2/orders/preview',
      '/api/v2/shipping_options',
      '/api/v2/carts',
      `/api/v2/products/frequently_bought_together`,
    ],
  })
  useOrderPreview({ cart: true })
  useFrequentlyBoughtTogetherAlgolia()
  return mutation
}

export default useAddToCart
